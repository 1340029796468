import http from "@/service/http.js";
const dashboard = {
  getContadorLote(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/contadores-lotes?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getContadores(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/contadores?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getResumoFinanceiro(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/resumo-financeiro?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLancesPorTurno(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/lances-turno?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getLoteCondicional(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/lote-condicional?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLancesPorDia(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/lances-dia?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLancesPorHora(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`dashboard/lances-hora?LeilaoId=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default dashboard;
