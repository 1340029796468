<template>
  <v-row no-gutters>
    <Selecionar :selecionar="selecionar">
      <v-select
        dense
        hide-details
        :items="leiloes"
        item-text="nome"
        label="Selecione um Leilão"
        outlined
        v-model="leilaoSelecionado"
        return-object
        class="mb-5"
        style="background-color: #ffffff"
        @change="onChangeLeilao"
      >
        <template v-slot:item="{ item }">
          <v-list-item>
            <v-list-item-action>
              <v-icon
                :color="item.status == 'Em Andamento' ? 'success' : 'error'"
              >
                mdi-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.nome }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </Selecionar>

    <!-- coluna 2 -->

    <v-col
      v-show="!coluna3Expandida"
      cols="12"
      md="11"
      lg="11"
      xl="11"
      style="background-color: #f3f6ff"
      :class="mobile ? '' : 'virtual-scroll'"
      :style="mobile ? '' : 'height:' + (widownHeight + 100) + 'px'"
    >
      <v-row no-gutters class="painel px-3 pt-5">
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
          class="pt-2"
          :class="{ 'pr-5': !mobile }"
        >
          <v-select
            dense
            hide-details
            :items="leiloes"
            item-text="nome"
            label="Selecione um Leilão"
            v-model="leilaoSelecionado"
            return-object
            class="mb-5"
            style="background-color: #ffffff"
            @change="onChangeLeilao"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="6" class="pa-0">
              <span class="font-weight-bold">Localização:</span>
              <h5>{{ leilaoSelecionado.localizacao }}</h5>
            </v-col>
            <v-col cols="6" class="pa-0" :class="{ 'pl-3': mobile }">
              <span class="font-weight-bold">Data:</span>
              <h5>{{ leilaoSelecionado.dataLeilao | formataDataHora }}</h5>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">VISITANTES</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.visitantes) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">LANCES</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.lances) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">HABILITADOS</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.habilitados) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">PARTICIPANTES</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.participantes) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary text-truncate">
                LOTES POR PARTICIPANTES
              </p>
              <p class="font-weight-bold display-0">
                {{
                  (contadores && contadores.lotesParticipantes.toFixed(0)) || 0
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">LANCES POR LOTE</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.lancesPorLote.toFixed(0)) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">TICKET MÉDIO</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.ticketMedio) | fomataMoeda }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">% AVALIAÇÃO</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.avaliacao.toFixed(0)) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="2" xl="2">
          <v-card elevation="0" rounded="lg">
            <v-card-text>
              <p class="text--secondary">% MINIMO VENDAS</p>
              <p class="font-weight-bold display-0">
                {{ (contadores && contadores.minimoVenda.toFixed(0)) || 0 }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-card elevation="0" rounded="lg" class="mb-5">
            <v-card-text>
              <span class="text--secondary">LOTES OFERECIDOS</span>
              <span class="font-weight-bold text-h6">
                {{ contadorLote.total || 0 }}
              </span>
              <Pie
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="'DoughnutChart'"
                :dataset-id-key="'label'"
                :width="250"
                :height="250"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-card elevation="0" rounded="lg" class="mb-5">
            <v-card-text>
              <p class="text--secondary mb-2">LANCES POR TURNO</p>

              <Pie
                :chart-options="chartOptions"
                :chart-data="chartDataTurno"
                :chart-id="'pieChartTurno'"
                :dataset-id-key="'label'"
                :width="250"
                :height="250"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-card elevation="0" rounded="lg" class="mb-5">
            <v-card-text>
              <p class="text--secondary mb-2">LOTES COM LANCES CONDICINAIS</p>

              <Pie
                :chart-options="chartOptions"
                :chart-data="chartDataLoteCondicional"
                :chart-id="'pieChartCondicional'"
                :dataset-id-key="'label'"
                :width="250"
                :height="250"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-5">
          <v-card elevation="0" rounded="lg">
            <v-card-title>Resumo Financeiro</v-card-title>

            <v-row>
              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">AVALIAÇÃO</p>
                <p class="font-weight-black">
                  {{ resumoFinanc.avaliacao | fomataMoeda }}
                </p>
              </v-col>

              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">MÍNIMO VENDAS</p>
                <p class="font-weight-black">
                  {{ resumoFinanc.minimoVendas | fomataMoeda }}
                </p>
              </v-col>

              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">ARREMATE</p>
                <p class="font-weight-bold">
                  {{ resumoFinanc.arremate | fomataMoeda }}
                </p>
              </v-col>

              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">TAXAS</p>
                <p class="font-weight-black">
                  {{ resumoFinanc.taxas | fomataMoeda }}
                </p>
              </v-col>

              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">COMISSÃO</p>
                <p class="font-weight-black">
                  {{ resumoFinanc.comissao | fomataMoeda }}
                </p>
              </v-col>

              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <p class="text--secondary">TOTAL</p>
                <p class="font-weight-black">
                  {{ resumoFinanc.total | fomataMoeda }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" rounded="lg" class="mb-5">
            <v-card-text>
              <p class="text--secondary">LANCES POR DIA</p>

              <Bar
                :chart-options="chartBarOptions"
                :chart-data="chartDataLanceDia"
                :chart-id="'barChartDia'"
                :dataset-id-key="'label'"
                width="200px"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card elevation="0" rounded="lg" class="mb-5">
            <v-card-text>
              <p class="text--secondary">LANCES POR HORA</p>

              <Bar
                :chart-options="chartBarOptions"
                :chart-data="chartDataLanceHora"
                :chart-id="'barChartHora'"
                :dataset-id-key="'label'"
                width="200px"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- coluna 3 -->
    <transition name="slide" mode="out-in">
      <v-col
        cols="12"
        :md="!coluna3Expandida ? 1 : 12"
        :lg="!coluna3Expandida ? 1 : 12"
        :xl="!coluna3Expandida ? 1 : 12"
        align="center"
        style="background-color: #d7ebf2; position: relative"
      >
        <h3 :class="!coluna3Expandida ? 'text-center' : ''">LOTES</h3>

        <v-row
          justify="center"
          id="virtual"
          :style="'height:' + widownHeight + 100 + 'px'"
          class="virtual-scroll"
          v-scroll:#virtual="onScroll"
        >
          <CardLote
            v-for="(lote, i) in ultimasArrematacoes"
            :key="i"
            :lote="lote"
            :min="cardMin"
            :primeiro="true"
            :showMinimizar="false"
            :showCountdown="true"
            :class="{ 'mr-5': !cardMin }"
          />
          <div class="text-center">
            <v-btn icon :loading="loading"> </v-btn>
          </div>
        </v-row>

        <v-btn
          fab
          dark
          x-small
          color="#FFF"
          style="position: fixed; top: 100px"
          :style="coluna3Expandida ? 'right: 95%;' : 'right: 7%;'"
          class="hidden-sm-and-down"
          v-if="leilaoSelecionado.nome"
          @click="expandir"
        >
          <v-icon v-if="!coluna3Expandida" color="#2bbdeb"
            >mdi-chevron-left</v-icon
          >
          <v-icon v-else color="#2bbdeb">mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn
          fab
          dark
          x-small
          color="#FFF"
          @click="scrollDown"
          style="position: fixed; bottom: 10px"
          :style="coluna3Expandida ? 'right: 40%;' : 'right: 3%;'"
          v-if="!coluna3Expandida && !toTop"
          class="hidden-sm-and-down"
        >
          <v-icon color="#2bbdeb">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn
          :disabled="loading"
          color="#FFF"
          @click="paginacao"
          style="position: fixed; bottom: 10px"
          :style="'right: 50%;'"
          v-else-if="coluna3Expandida"
          class="hidden-sm-and-down"
          :loading="loading"
        >
          <v-icon color="#2bbdeb">mdi-chevron-down</v-icon>
          MAIS
        </v-btn>
        <v-btn
          fab
          dark
          x-small
          color="#FFF"
          @click="scrollTop"
          style="position: fixed; right: 3%; bottom: 10px"
          v-else
          class="hidden-sm-and-down"
        >
          <v-icon color="#2bbdeb">mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </transition>
  </v-row>
</template>

<script>
import CardLote from "../components/card-lote.vue";
import Comitente from "@/service/comitente";
import Dashboard from "@/service/dashboard";
import Selecionar from "./selecionar.vue";

import Leilao from "@/service/leilao";

import { Pie, Bar } from "vue-chartjs/legacy";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels
);
import { mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    CardLote,
    Pie,
    Bar,
    Selecionar,
  },
  data() {
    return {
      coluna3Expandida: false,
      top: 0,
      toTop: false,
      comitente: null,
      contadorLote: {
        total: 0,
      },
      contadores: null,
      leiloes: [],
      leilaoSelecionado: {},
      lotes: [],

      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 10,
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "black",
              },
            },
            formatter: function (value) {
              if (value == 0) return "";
              return value;
            },
          },
        },
      },
      ultimasArrematacoes: [],
      loading: false,
      resumoFinanc: {},
      lancesPorTurno: null,
      chartDataTurno: {},
      loteCondicional: null,
      chartDataLoteCondicional: {},
      chartDataLanceDia: {},
      lancesPorDia: null,
      chartBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "black",
              },
            },
            formatter: function (value) {
              if (value == 0) return "";
              return value;
            },
          },
        },
      },
      chartDataLanceHora: {},
      lancesPorHora: null,
    };
  },
  computed: {
    widownHeight() {
      return window.innerHeight * 0.84;
    },
    selecionar() {
      return !this.leilaoSelecionado.nome;
    },
    cardMin() {
      return (
        !["sm", "xs"].includes(this.$vuetify.breakpoint.name) &&
        !this.coluna3Expandida
      );
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions(["setAuth"]),
    ...mapMutations(["setTituloPagina"]),
    async expandir() {
      this.coluna3Expandida = !this.coluna3Expandida;
      this.scrollTop();
    },

    onScroll(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById("virtual");

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.paginacao();
        this.toTop = true;
        return;
      }
      this.toTop = false;
    },
    scrollDown() {
      this.top = this.top + 100;
      this.$nextTick(() => {
        document.getElementById("virtual").scroll({
          top: this.top,
          behavior: "smooth",
        });
      });
    },
    scrollTop() {
      this.top = 0;
      document.getElementById("virtual").scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    async paginacao() {
      const { id } = this.leilaoSelecionado;
      const page = this.lotes.pageNumber + 1;

      if (page > 1 && page > this.lotes.totalPages) return;
      this.loading = true;
      this.lotes = await Leilao.getLotes({ id, page });
      this.ultimasArrematacoes.push(...this.lotes.data);
      this.loading = false;
    },
    async onChangeLeilao() {
      const { id } = this.leilaoSelecionado;
      this.onJoinGroup(id);
      this.ultimasArrematacoes = [];
      const lotes = await Leilao.getLotes({ id, page: 1, status: "aberto" });
      this.lotes = lotes;
      this.ultimasArrematacoes.push(...lotes.data);

      this.atualizaGraficos();
    },

    async atualizaGraficos() {
      const { id } = this.leilaoSelecionado;
      const respContLote = await Dashboard.getContadorLote(id);
      this.contadorLote = respContLote.data;
      const respCont = await Dashboard.getContadores(id);
      this.contadores = respCont.data;
      const respResumo = await Dashboard.getResumoFinanceiro(id);
      this.resumoFinanc = respResumo.data;
      const respLacesTurno = await Dashboard.getLancesPorTurno(id);
      this.lancesPorTurno = respLacesTurno.data;

      const respLoteCond = await Dashboard.getLoteCondicional(id);
      this.loteCondicional = respLoteCond.data;

      const respLoteDia = await Dashboard.getLancesPorDia(id);
      this.lancesPorDia = respLoteDia.data;

      const respLoteHora = await Dashboard.getLancesPorHora(id);
      this.lancesPorHora = respLoteHora.data;

      this.inicializaGrafico();
      this.inicializaGraficoTurno();
      this.inicializaGraficoLoteCondicional();
      this.inicializaGraficoLancesPorDia();
      this.inicializaGraficoLancesPorHora();
    },

    async inicializa() {
      try {
        const resp = await Comitente.getComitente(1);
        this.comitente = resp;

        const leiloes = await Leilao.getLeiloes();
        this.leiloes = leiloes.data;

        if (this.leiloes.length == 1) {
          this.leilaoSelecionado = this.leiloes[0];
          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
        }
      } catch (e) {
        //console.error(e);
      }
    },

    inicializaGrafico() {
      const data = { ...this.contadorLote };
      delete data.total;
      this.chartData = {
        labels: Object.keys(data).map((e) =>
          e
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase()
        ),
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: Object.values(data),
            hoverOffset: 4,
          },
        ],
      };
    },

    inicializaGraficoLoteCondicional() {
      let obj = {};
      const { acimaCondicional, abaixoCondicional } = this.loteCondicional;
      obj = { acimaCondicional, abaixoCondicional };
      this.chartDataLoteCondicional = {
        labels: Object.keys(obj).map((e) =>
          e
            .split(/(?=[A-Z])/)
            .join(" do ")
            .toLowerCase()
        ),
        datasets: [
          {
            backgroundColor: ["#00D8FF", "#41B883", "#DD1B16", "#E46651"],
            data: Object.values(obj),
            hoverOffset: 4,
          },
        ],
      };
    },

    inicializaGraficoTurno() {
      const obj = {};
      this.lancesPorTurno.reverse().forEach((e) => (obj[e.turno] = e.lances));
      this.chartDataTurno = {
        labels: Object.keys(obj),
        datasets: [
          {
            backgroundColor: ["#00D8FF", "#41B883", "#DD1B16", "#E46651"],
            data: Object.values(obj),
            hoverOffset: 4,
          },
        ],
      };
    },

    inicializaGraficoLancesPorDia() {
      const obj = {};
      this.lancesPorDia
        .sort(
          (a, b) =>
            moment(a.dia, "YYYY-MM-DD").valueOf() -
            moment(b.dia, "YYYY-MM-DD").valueOf()
        )
        .forEach(
          (e) =>
            (obj[moment(e.dia, "YYYY-MM-DD").format("DD/MM/YY")] = e.lances)
        );
      this.chartDataLanceDia = {
        labels: Object.keys(obj),
        datasets: [
          {
            label: "Lances",
            backgroundColor: ["#00D8FF"],
            data: Object.values(obj),
            hoverOffset: 4,
          },
        ],
      };
    },
    inicializaGraficoLancesPorHora() {
      const obj = {};
      this.lancesPorHora.forEach((e) => (obj[e.hora] = e.lances));
      this.chartDataLanceHora = {
        labels: Object.keys(obj),
        datasets: [
          {
            label: "Lances",

            backgroundColor: Object.keys(obj).map((e) =>
              this.getCorPorTurno(e)
            ),
            data: Object.values(obj),
            hoverOffset: 4,
          },
        ],
      };
    },

    getCorPorTurno(hora) {
      if (["0", "1", "2", "3", "4"].includes(hora)) return "#E46651";
      if (["5", "6", "7", "8", "9", "10", "11"].includes(hora))
        return "#00D8FF";
      if (["12", "13", "14", "15", "16", "17", "18"].includes(hora))
        return "#41B883";
      if (["19", "20", "21", "22", "23"].includes(hora)) return "#DD1B16";
    },

    async onEventNewLance(loteinfo) {
      if (loteinfo.status === "Encerrado") {
        const { id } = this.leilaoSelecionado;
        const lotes = await Leilao.getLotes({ id, page: 1, status: "aberto" });
        this.lotes = lotes;
        this.ultimasArrematacoes = [...lotes.data];
      }

      const lote = this.ultimasArrematacoes.find(
        (e) => e.loteId == loteinfo.loteId
      );
      lote.info = { ...loteinfo };

      this.atualizaGraficos();
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },
  },
  filters: {
    formataData(val) {
      if (!val) return "";
      return new Date(val).toLocaleString().substring(0, 10);
    },
    formataDataHora(val) {
      if (!val) return "";
      return moment(val).utc().local().format("DD/MM/YYYY HH:mm");
    },
    fomataMoeda(val) {
      if (!val) return "0";
      return val.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  async created() {
    this.setTituloPagina("PAINEL DO COMITENTE");
    await this.inicializa();
    this.$signalR.$on("new-lance", this.onEventNewLance);
  },

  beforeDestroy() {
    this.$signalR.$off("new-lance", this.onEventNewLance);
  },
};
</script>

<style scoped>
.display-0 {
  font-size: 1.2rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}
p {
  margin-bottom: 0px;
}

.ativo {
  color: #00791e;
}

.inativo {
  color: #ff5252;
}

.virtual-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.virtual-scroll::-webkit-scrollbar {
  display: none;
}

.slide-leave-active,
.slide-enter-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.slide-enter {
  opacity: 0;
}

.slide-leave-to {
  opacity: 0;
}

span,
p.text--secondary {
  font-size: 11px;
}

.painel span {
  font-size: 12px;
}
</style>
